.table-container {
  width: 80%;
  margin: 20px auto;
  background-color: #fff;
  border-radius: 15px;
  border-top: 0.5px solid #ddd;
  box-shadow: 0px 8px 8px rgba(0, 0, 0, 0.1); 
  overflow: hidden;

}

.comparison-table {
  table-layout: fixed;
  width: 100%;
  border-collapse: collapse;
  border-spacing: 0;
  font-size: 0.7rem; /* Reduced font size */
  /* border-radius: 20px; */
  box-shadow: 0px 8px 8px rgba(0, 0, 0, 0.1); /* Increased spread value for more shadow on top */
  margin: 0 auto;
}



/* Adjusted padding for cells */
.comparison-table th,
.comparison-table td {
    padding: 0.15rem; /* Reduced padding */
    border-left: 0;
    border-bottom: 1px solid #ddd;
    border-right: 1px solid #ddd; /* Add inner vertical border */
    
}

.comparison-table th:first-child {
    width: 35%; /* Adjust the width of the first column */
}

.comparison-table th:nth-child(2n) {
    box-shadow: 0 0 0rem hsla(0, 0%, 0%, 0.15);
    
}

/* Add alternating gray background color to rows */
.comparison-table tr:nth-child(even) {
    background-color: #f8f8f8;
}

.comparison-table td:not(:first-child) {
    text-align: center;
}

.comparison-table thead {
    border-radius: 20px 20px 0 0 ; /* Adjust border radius for top-left and top-right corners */
}







@media (min-width: 48rem) {
    .comparison-table {
        font-size: 0.8rem; /* Adjusted font size for larger screens */
    }

    .comparison-table th,
    .comparison-table td {
        padding: 0.5rem; 
    }
}
.comparison-table td:first-child {
  padding-left: 1.5rem; /* Adjust the left padding for the first column */
}


@media (min-width: 60rem) {
    .comparison-table {
        font-size: 0.9rem; /* Adjusted font size for even larger screens */
    }

    .comparison-table th,
    .comparison-table td {
        padding: 0.55rem; 
        
    }
}


  
  
  
  
  


:root {
    --Third: #000000;
    --secondary: #c76358; /* Define secondary color */
    --primary: #8cc63e;
    --checkmarkColor: white;
    --size: 20px; /* Adjusted smaller size */
    --checkmarkFat: 2px; /* Adjusted thicker thickness */
  }
  

  /* Circle styles */
  .circle {
    /* justify-content: center; */
    position: relative;
    background: var(--primary);
    border-radius: 50%;
    width: var(--size);
    height: var(--size);
  }

  .circles {
    /* justify-content: center; */
    position: relative;
    background: var(--secondary);
    border-radius: 50%;
    width: var(--size);
    height: var(--size);
  }
  .circless {
    /* justify-content: center; */
    position: relative;
    background: var(--Third);
    border-radius: 50%;
    width: var(--size);
    height: var(--size);
  }
  
  /* Checkmark styles */
  .checkmark {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%) rotate(45deg); /* Center and rotate */
    height: calc(var(--size) * 0.5); /* Adjusted height */
    width: calc(var(--size) * 0.3); /* Adjusted width */
    border-bottom: var(--checkmarkFat) solid var(--checkmarkColor);
    border-right: var(--checkmarkFat) solid var(--checkmarkColor);
  }
  /* Circle container */
.circle-container {
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  .checkmark-secondary {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%) rotate(45deg); /* Center and rotate */
    height: calc(var(--size) * 0.5); /* Adjusted height */
    width: calc(var(--size) * 0.3); /* Adjusted width */
    border-bottom: var(--checkmarkFat) solid var(--checkmarkColor);
    border-right: var(--checkmarkFat) solid var(--checkmarkColor);
    background-color: var(--secondary); /* Red background */
  }
  

  .checkmark-Third {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%); /* Center the X mark */
    width: calc(var(--size) * 0.6); /* Adjusted width */
    height: calc(var(--size) * 0.6); /* Adjusted height */
  }
  
  /* Diagonal lines of the X */
  .checkmark-Third::before,
  .checkmark-Third::after {
    content: '';
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%) rotate(45deg); /* Center and rotate */
    width: var(--checkmarkFat);
    height: 100%;
    background-color: var(--checkmarkColor);
  }
  
  /* Cross lines of the X */
  .checkmark-Third::after {
    transform: translate(-50%, -50%) rotate(-45deg); /* Rotate in the opposite direction */
  }
  


