/* Modal Overlay */
.react-modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.2);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 999999;
  }
  
  /* Modal Content */
  .react-modal-content {
    background-color: white;
    padding: 20px;
    border-radius: 8px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.3);
    z-index: 999999;
  }
  
  /* Form Input */
  .react-modal-content input[type="text"],
  .react-modal-content input[type="email"],
  .react-modal-content textarea {
    width: 100%;
    padding: 10px;
    margin-bottom: 15px;
    border: 1px solid #ccc;
    border-radius: 5px;
    box-sizing: border-box;
  }
  
  /* Form Button */
  .react-modal-content button[type="submit"] {
    width: 100%;
    padding: 10px;
    background-color: #007bff;
    color: white;
    border: none;
    border-radius: 5px;
    cursor: pointer;
  }
  
  /* Close Button */
  .react-modal-content .close-btn {
    position: absolute;
    top: 20px;
    right: 20px;
    cursor: pointer;
    background: none;
    border: none;
  }
  
  /* Add this CSS */
.react-modal-overlay.open-modal {
    overflow: hidden;
  }
  
/* Background Overlay */
.open-modal {
    overflow: hidden; /* Disable scrolling */
  }
/* Override default focus styling for input elements */

  /* Remove default focus styling for input and textarea elements within modal content */
/* Remove default focus styling for input and textarea elements within modal content */
.react-modal-content input:focus-visible,
.react-modal-content textarea:focus-visible {
  outline: none; /* Remove outline */
  box-shadow: none; /* Remove box shadow */
}
/* Remove default focus styling for input and textarea elements within modal content */
.react-modal-content input:focus,
.react-modal-content textarea:focus {
  outline: none; /* Remove outline */
  box-shadow: none; /* Remove box shadow */
}
/* Close Button */
.close-btn {
    position: absolute;
    top: 10px;
    right: 10px;
    cursor: pointer;
    background: none;
    border: none;
    z-index: 999999; /* Ensure it's above other content */
    color: black; /* Adjust the color as needed */
}
.react-modal-content {
    position: relative;
    /* Other styles */
}


.toast {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: #4caf50;
  color: white;
  padding: 15px 30px;
  border-radius: 5px;
  z-index: 9999;
}
