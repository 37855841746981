/* styles.css */

.benefit-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-evenly; /* Adjust this property for equal spacing */
}

.benefit {
  border-radius: 8px;
  background-color: #ffffff;
  width: calc(24.33% - 20px);
  margin: 10px;
  padding: 10px;
  position: relative;
  overflow: hidden;
  transition: transform 0.3s ease-in-out;
  box-shadow: 4px 4px 10px rgba(0, 0, 0, 0.2);
  text-align: center;
}

.benefit-block {
  color:'243E63';
  font-size: 20px;
  color: #243e63;
  padding: 10px;
  margin-bottom: 5px;
}

.benefit-image {
  max-width: 80px;
  margin: 0 auto;
}

/* Mobile responsiveness */
@media screen and (max-width: 768px) {
  .benefit {
    width: calc(50% - 20px);
  }
}

@media screen and (max-width: 480px) {
  .benefit {
    width: calc(100% - 20px);
  }
}






/* styles.css */

.benefits-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-evenly;
  text-align: center;
}

.benefits {
  background-color: #F1F1F1;
  width: calc(26.33% - 2.3125rem);
  margin: 1.25rem;
  position: relative;
  overflow: hidden;
  transition: transform 0.3s ease-in-out;
  border-radius: 0.5rem;
  justify-content: space-evenly;
  height: 400px;
}

.benefits-block {
  font-size: 1rem;
  color: #243E63;
  padding-right: 0.625rem;
  padding-left: 0.625rem;
  /* margin-bottom: 0.625rem; */
  padding-top: 0.8rem;
}

.benefits-description {
  padding:10px 20px 20px 10px;
  z-index: 1;
  
}

.benefits img {
  width: 100%; /* Ensure the image takes the full width of its container */
  height: 45%; 
  position: absolute;
  bottom: 0;
  z-index: 0;
}
.image-container {
  margin-bottom: 200px; /* Add margin to create space between images */
}

/* Media Query for Tablet Devices */
@media (max-width: 1124px) {
  .benefits {
    width: calc(28% - 3rem); /* Adjust width to fit two cards in a row */
    margin: 1rem; /* Adjust margin to create space between cards */
height: auto; /* Reset height for responsiveness */
    display: flex; /* Use flexbox for layout */
    flex-direction: column; /* Stack image and description vertically */
  }  

  .benefits img {
    position: relative;
    bottom: 0;
  }

  .benefits-description {
    padding: 10px;
  }
}
/* Media Query for Tablet Devices */
/* Media Query for Tablet Devices */
@media (max-width: 1024px) {
  .benefits {
    width: calc(48% - 2rem); /* Adjust width to fit two cards in a row */
    margin: 1rem; /* Adjust margin to create space between cards */
    height: auto; /* Reset height for responsiveness */
    display: flex; /* Use flexbox for layout */
    flex-direction: column; /* Stack image and description vertically */
  }

  .benefits img {
    /* width: 100%; */
    height: 80%;
    bottom: 0;
  }

  .benefits-description {
    padding: 10px; /* Adjust padding for description */
  }
}



/* Media Query for Mobile Devices */
@media (max-width: 568px) {
  .benefits {
    width: calc(100% - 2rem);
    margin: 1rem;
    height: 40%; /* Adjust the height for responsiveness */
  }

  .benefits-block {
    padding-top: 1.5rem; /* Adjust spacing for better readability on mobile */
  }

  .benefits img {
    position: relative; 
    height: auto; 
    bottom: 0; 
  }
}



/* CSS */
.heading {
margin-top: 1rem; /* equivalent to mt-4 */
font-weight: 900; /* equivalent to font-black */
text-align: left; /* equivalent to text-left */
font-size: 1.575rem; /* equivalent to text-3xl */
line-height: 1.2; /* equivalent to leading-tight */
}

@media screen and (min-width: 640px) {
.heading {
  font-size: 2.25rem; /* equivalent to sm:text-4xl */
}
}

@media screen and (min-width: 1024px) {
.heading {
  font-size: 3rem; /* equivalent to lg:text-5xl */
}
}

@media screen and (min-width: 768px) {
.heading {
  text-align: center; /* equivalent to text-center */
}
}





.tooltip-container {
  position: relative;
  display: inline-block;
}

.tooltip {
  visibility: hidden;
  width: max-content;
  background-color: rgba(0, 0, 0, 0.7);
  color: white;
  text-align: center;
  border-radius: 4px;
  padding: 4px 8px;
  position: absolute;
  z-index: 1;
  top: 125%; /* Position below the anchor tag */
  left: 50%;
  transform: translateX(-50%);
}

.tooltip-container:hover .tooltip {
  visibility: visible;
}



