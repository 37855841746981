/* Card.module.css */
.container {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  justify-content: center; /* Center cards horizontally */
}

.card {
  flex: 0 0 calc(50% - 20px);
  margin: 10px; /* Adjust margin for spacing between cards */
  /* padding: 15px; */
  border: none;
  border-radius: 5px;
  transition: transform 0.3s, box-shadow 0.3s;
  cursor: pointer;
  box-sizing: border-box; 
}

.card:hover {
  transform: scale(1.05);
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
}

.content {
  display: flex;
  align-items: center;
}

.logo {
  width: 54px;
  margin-right: 10px;
}

.text {
  display: flex;
  flex-direction: column;
}
.title {
  font-weight: bold;
}

@media screen and (max-width: 768px) {
  .card {
    flex-basis: calc(50% - 20px);
    margin: 5px; /* Reduce margin for smaller screens */
  }
}



/* benefitStyle */
.benefits-container {
  content: #243E63;
  /* Your container styles here */
}

.benefits-wrapper {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}

.benefit {
  background-color: #ffffff;
  width: calc(24.33% - 40px);
  margin: 10px;
  padding: 10px;
  position: relative;
  overflow: hidden;
  transition: transform 0.3s ease-in-out;
  box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.2);
  text-align: center;
}

.benefit-block {
  font-size: 20px;
  color: #359fff;
  padding: 10px;
  margin-bottom: 5px;
}

.benefit-image {
  max-width: 100px;
  margin: 0 auto;
}

/* Separate classes for each benefit */
.benefit-extraction {
  /* Specific styles for Extraction benefit here */
}


.btn2 {
  position: relative;
  display: inline-block;
  padding: 15px 30px;
  border: 2px solid #ff0000;
  text-transform: uppercase;
  color: #ff0000;
  text-decoration: none;
  font-weight: 600;
  font-size: 20px;
  transition: 0.3s;
}

.btn2::before {
  content: '';
  position: absolute;
  top: -2px;
  left: -2px;
  width: calc(100% + 4px);
  height: calc(100% - -2px);
  background-color: #ffffff;
  transition: 0.3s ease-out;
  transform: scaleY(1);
}

.btn2::after {
  content: '';
  position: absolute;
  top: -2px;
  left: -2px;
  width: calc(100% + 4px);
  height: calc(100% - 50px);
  background-color: #212121;
  transition: 0.3s ease-out;
  transform: scaleY(1);
}

.btn2:hover::before {
  transform: translateY(-25px);
  height: 0;
}

.btn2:hover::after {
  transform: scaleX(0);
  transition-delay: 0.15s;
}

.btn2:hover {
  border: 2px solid #ff0000;
}

.btn2 span {
  position: relative;
  z-index: 3;
}

button {
  text-decoration: none;
  border: none;
  background-color: transparent;
}